<template>
  <section id="bottom-call" class="relative overflow-hidden 2xl:px-0">
    <div class="bg-white py-12 lg:pt-36 lg:pb-24 2xl:w-10/12 2xl:max-w-full xl:max-w-7xl lg:mx-auto rounded-md">
      <div class="max-w-xl mx-auto text-center">
        <h5 class="text-black lg:text-7xl text-4xl px-12 lg:px-0 mb-8 gradient-clip" style="letter-spacing: -2px;">{{ $t('footer.ready_to_start') }}<br/><span>{{ $t('footer.your_project') }}</span>?<br></h5>
        <h6 class="text-black text-xl px-12 mb-8">{{ $t('footer.our_team_of_developers') }}</h6>
        <nuxt-link to="/contact" class="button button-outline"><span></span>{{ $t('footer.contact_us') }}</nuxt-link>
      </div>
    </div>
  </section>
</template>
